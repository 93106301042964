import { useState, useEffect } from 'react';
import { isStringURLAnExternalURL } from '../Utils';

export function useExternalUrl(href: string): boolean {
    const [isExternalUrl, setIsExternalUrl] = useState<boolean>(false);

    useEffect(() => {
        setIsExternalUrl(isStringURLAnExternalURL(href));
    }, [href]);

    return isExternalUrl;
}

export default useExternalUrl;
