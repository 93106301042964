import { Link as DDSLink, LinkProps as DDSLinkProps } from '@norges-domstoler/dds-components';
import { useLinkClickHandler } from 'react-router-dom';
import { getRelativeUrl } from '../Utils';
import { useExternalUrl } from './useExternalUrl';

type LinkProps = DDSLinkProps & { langRef?: React.MutableRefObject<undefined> };

export default function Link(props: LinkProps) {
    const { children, href, langRef, ...rest } = props;
    const url = getRelativeUrl(href);
    const linkClickHandler = useLinkClickHandler(url);

    const isExternalUrl = useExternalUrl(href);

    return (
        <DDSLink
            ref={langRef}
            href={isExternalUrl ? href : url}
            onClick={isExternalUrl ? undefined : linkClickHandler}
            {...rest}
        >
            {children}
        </DDSLink>
    );
}
