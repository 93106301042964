import { Card, NavigationCardProps as DDSNavigationCardProps } from '@norges-domstoler/dds-components';
import { useLinkClickHandler } from 'react-router-dom';
import { getRelativeUrl } from '../Utils';
import { useExternalUrl } from './useExternalUrl';

type NavigationCardProps = Omit<DDSNavigationCardProps, 'htmlProps' | 'cardType'> & {
    useBrowserNavigation?: boolean;
    scrollToTop?: boolean;
};

export default function NavigationCard(props: NavigationCardProps) {
    const { children, useBrowserNavigation, href, ...rest } = props;
    const url = getRelativeUrl(href);
    const linkClickHandler = useLinkClickHandler(url);

    const isExternalUrl = useExternalUrl(href);

    const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (!isExternalUrl) {
            document.documentElement.scrollTop = 0;
            return linkClickHandler(e);
        }
    };

    return (
        <Card
            {...rest}
            href={isExternalUrl ? href : url}
            cardType="navigation"
            htmlProps={useBrowserNavigation ? undefined : { onClick: handleOnClick }}
        >
            {children}
        </Card>
    );
}
